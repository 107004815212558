@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arima:wght@100;300;400;500;700&family=Eczar:wght@400;500;700&family=Hind+Madurai:wght@300;400;500;700&family=Hind+Siliguri:wght@300;400;500;700&family=Laila:wght@300;400;500;700&family=Noto+Sans+Malayalam:wght@100;300;400;500;700&family=Noto+Sans+Tamil:wght@100;300;400;500;700&family=Noto+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400&family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,400&family=Rozha+One&family=Tiro+Bangla:ital@0;1&family=Tiro+Telugu:ital@0;1&display=swap');

.outercontainer{
    position: absolute;
    width: 100%;
    max-width: 358px;
    height: 100vh;
    padding: 20px 0;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.head{
    width: 90%;
    height: fit-content;
    /* border: 1px solid red; */
    display: flex;
    padding: 5px 0;
    padding-bottom: 15px;
    /* border-bottom: 1px solid rgba(228, 228, 228, .30); */
    margin: 0 auto;
    }
.arrow{
    width: 10%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    }
.topic{
    width: 90%;
    /* color: white; */
    text-align: center;
    font-size: 17px;
    font-weight: 700;
    padding-right: 28px;
}
.buttoncontainer{
    bottom: 0;
    position: relative;
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
}
button{
    border: none;
}
.change{
    padding-top: 20px;
    width: 95%;
    margin: 0 auto;
    position: relative;
    height: max-content;
}
.pwdtag{
    font-size: 14px;
}
.editable{
    height:650px;
    overflow: auto;
    
}

.inputtag{
    width: 90%;
    height: 40px;
    color: white;
    border: none;
    background-color: transparent;
    font-size: 14px;
}
.inputtag:focus{
    outline: none;
}
.inputbtns{
    box-shadow: 0 0 2px;
    padding: 5px 15px;
    margin: 5px 0;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-radius: 5px;
}


.newpwd{
    margin-top: 20px;
}
.confirmpwd{
    margin-top: 20px;
}
.must{
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.blur{
    opacity: 0.3;
    cursor: pointer;
}
.forgot{
    display: block;
    text-align: end;
    font-size: 12px;
    padding-top: 2px;
}
.check{
    margin-top: 12px;
    font-size: 14px;
}




.custom-checkbox input[type="checkbox"] {
    display: none;
  }
/* Create a custom checkbox */
.custom-checkbox {
    display: inline-block;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 12px;
    user-select: none;
  }
  /* Checkmark (custom checkbox) */
.custom-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 3px;
    height: 18px;
    width: 18px;
    background-color: transparent;
    border-radius: 4px;
    border: 2px solid #000;
  }
/* When the checkbox is checked, add a blue background */
.custom-checkbox input[type="checkbox"]:checked + .checkmark {
    background-color: transparent;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .custom-checkbox .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .custom-checkbox input[type="checkbox"]:checked + .checkmark:after {
    display: block;
  }
  /* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 4px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: 1px solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}  


/* media query for mobile */
@media (max-width: 767px) {
    .outercontainer{
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
    }
    .editable{
        max-width: 100%;
    }
}
.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    text-align: center;
  }
  
  .loader {
    border: 16px solid #d3d9dd; 
    border-top: 16px solid #b4b4b4;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1.5s linear infinite;
    margin-bottom: 20px; 
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }




