.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; 
  text-align: center;
}

.loader {
  border: 16px solid #f3f3f3; 
  border-top: 16px solid #b4b4b4; 
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1.5s linear infinite;
  margin-bottom: 20px; 
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.message {
  font-weight: bold;
  font-size: 32px;
  font-weight: bold;
  padding: 10px;
}

.sub-message {
  font-size: 1rem;
  color: #666;
}
