.flex-center {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 46px;
    padding: 0px 8px;
    cursor: pointer;
}

.ad-blocker-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.ad-blocker-back {
    padding: 16px 20px 6px;
    cursor: pointer;
}

.child-container1 {
    height: 600px;
    width: 28%;
    background-color: #F5F5FA;
    overflow: scroll;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    border-radius: 0px 0px 0px 8px;
}

.child-container2 {
    height: 520px;
    width: 73%;
    padding: 20px 50px 20px 20px;

}

.h-1 {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    height: 46px;
    margin-left: 20px;
}

.ad-blocker-img {
    width: 24px;
    height: 24px;
}

.ad-blocker-name {
    font-size: 16px;
    font-weight: 500;

}

.h-2 {
    font-size: 24px;
    font-weight: 600;
}

.ol {
    margin: 12px 20px;
}

.ol>li {
    margin-bottom: 4px;
    font-size: 15px;
    font-weight: 500;
}

.c-container {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.c-1 {
    width: 50%;
}

.c-2 {
    width: 40%;
}

.container {
    :global(.ant-modal-content) {
        padding: 0px;
    }
}

:global(.ant-modal-mask) {
    opacity: 0.1 !important;
}