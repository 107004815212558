:global(.ant-modal-mask) {
  background-color: #000000 !important;
  opacity: 0.8 !important;
}
:global(.ant-modal) {
  top: 60px;
}
.container {
  :global(.ant-modal-close) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
  }
  :global(.ant-modal-close:hover) {
    background-color: transparent;
  }

  :global(.ant-modal-header) {
    background-color: #f9f9fb;
    font-size: 14px;
    font-weight: 600;
    color: #292929;
    border-radius: 8px !important;
    border-bottom: none;
    padding: 16px 24px;
  }

  :global(.ant-modal-close-x) {
    display: flex;
    position: relative;
    left: 0px;
    bottom: 40px;
    width: 25px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: white;
    height: 25px;
    color: black;
    padding: 7px;
  }
  :global(.ant-btn-primary) {
    margin-top: 20px;
    background: #0353e9;
    border-radius: 6px;
    height: 32px !important;
    border-color: none;
    &:hover {
      background: transparent linear-gradient(247deg, #3d8dfa 0%, #005bd8 100%)
        0% 0% no-repeat padding-box;
      box-shadow: 0px 4px 10px #00000038;
      opacity: 1;
      border-color: #0353e9;
    }
    &:focus {
      border-color: #0353e9 !important;
    }
  }
  :global(.ant-btn) {
    text-align: center;
    border-color: none !important;
    &:hover {
      border-color: #0353e9 !important;
    }
    &:focus {
      border-color: #0353e9 !important;
    }
  }
  :global(.ant-modal-content) {
    padding: 0px;
  }
}
