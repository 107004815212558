.main-container {
    box-sizing: border-box;
    font-family: "Roboto";
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #15213d;
  }
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  .carousel-main-container {
    width: 70%;
    margin: auto;
    position: relative;
    scroll-snap-align: center;
  }
  .top-heading-container {
    text-align: center;
    margin: 24px 0px;
  }
  .large-heading {
    margin-bottom: 16px;
    font-size: 26px;
    font-weight: 500;
  }
  .description {
    line-height: 1.5;
    font-weight: 500;
    color: #676f81;
    font-size: 16px;
  }
  .logo-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 12px 8px;
  
    .logo {
      width: 140px;
    }
  }
  .checkbox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: #15213d;
    font-size: 14px;
    input[type="radio"] {
      margin: 3px 3px 0px 0px;
    }
  }
  .offer-img {
    width: 35px;
    height: 35px;
    border-radius: 5px;
  }
  .offers-main-container {
    margin-top: 20px;
    text-align: left;
    width: 100%;
  }
  
  .individual-offer {
    height: fit-content;
    display: flex;
    align-items: center;
    margin: 10px 0px;
    padding: 10px;
    gap: 15px;
    border-radius: 7px;
    border: solid 1px #f4f4f4;
    background-color: #fff;
  }
  
  .offer-text {
    font-size: 12px;
    color: #15213d;
    overflow: hidden;
    white-space: normal;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  
  .advantages {
    display: flex;
    margin-bottom: 10px;
    align-items: flex-start;
    font-size: 13px;
    gap: 8px;
    color: #15213d;
    text-align: left;
  }
  .tick {
    padding-right: 10px;
  }
  .coupon-code-container {
    display: flex;
    justify-content: space-between;
  }
  
  .coupon-code-text {
    height: 40px;
    border-radius: 5px;
    border: solid 1px #15213d;
    background-color: #fff;
    font-size: medium;
    padding: 0px 12px;
    font-size: 14px;
    width: -webkit-fill-available;
    &:focus {
      outline: none;
    }
  }
  .next-button {
    height: 40px;
    padding: 9px 13px;
    border-radius: 5px;
    border: solid 1px #15213d;
    background-color: #fff;
    margin-left: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      filter: brightness(0);
    }
  }
  
  .buy-now {
    width: 300px;
    height: 38px;
    border-radius: 68px;
    background-color: #e0011b;
    border: none;
    padding: 10px;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  .disabled {
    background-color: #aaaaaa;
    cursor: not-allowed !important;
  }
  .subscribe-btn {
    @extend .buy-now;
    width: 283px;
    height: 50px;
    position: absolute;
    bottom: -20px;
    left: 38px;
    background-color: #bc251c;
  }
  
  .validatedCouponCode {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background-color: #f1faee;
    padding: 6.6px 10.5px;
    border-radius: 4px;
    width: 300px;
  }
  
  .validatedCouponCode-position {
    @extend .validatedCouponCode;
    margin-top: 32px;
    position: absolute;
    bottom: 90px;
    left: 30px;
    width: 300px;
  }
  
  .message {
    display: flex;
    font-size: 13px;
    color: #15213d;
    align-items: center;
    justify-content: space-evenly;
    .verifiedIcon {
      margin-right: 10px;
    }
  }
  .remove {
    cursor: pointer;
    margin-bottom: 2px;
  }
  .show-more {
    cursor: pointer;
    font-size: 12px;
    width: fit-content;
    text-decoration: underline;
  }
  
  .error-msg {
    color: red;
    font-size: 12px;
  }
  .wrap-checkbox {
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
    gap: 10px;
  }
  
  .wrap-checkbox-container {
    position: absolute;
    width: 100%;
    bottom: 38px;
  }
  
  .free-style-text {
    font-size: 14px;
    color: #bd231b;
    font-weight: 400;
  }
  
  .loader-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  #loader {
    position: relative;
    display: flex;
    width: 25%;
    justify-content: space-between;
    margin-bottom: 20px; /* Add space between loader and text */
  }
  
  #loader div {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    background: #d91e36;
  }
  
  #loader div:nth-child(1) {
    animation: loader 3s linear infinite 0s;
  }
  
  #loader div:nth-child(2) {
    animation: loader 3s linear infinite 0.15s;
  }
  
  #loader div:nth-child(3) {
    animation: loader 3s linear infinite 0.3s;
  }
  
  #loader div:nth-child(4) {
    animation: loader 3s linear infinite 0.45s;
  }
  
  @keyframes loader {
    0% {
      opacity: 0;
      transform: scale(0.3);
      background: #59cd90;
    }
    25% {
      opacity: 1;
      transform: scale(1.8);
      background: #0072bb;
    }
    50% {
      opacity: 0;
      transform: scale(0.3);
      background: #fe4a49;
    }
    75% {
      opacity: 1;
      transform: scale(1.8);
      background: #fed766;
    }
    100% {
      opacity: 0;
    }
  }
  
  .loader-text {
    text-align: center;
    font-size: 16px;
    color: #333;
    font-family: Arial, sans-serif; /* or any preferred font */
    margin-top: 60px;
    font-size: 24px;
  }
  
  //new T1 Subs Card scss
  .subscription-container-t1 {
    width: 100%;
    background-color: #fffafb;
    margin-bottom: 50px;
    border-radius: 18px;
  }
  
  .recommended-tag-t1 {
    height: 44px;
    width: 100%;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ab001f;
    border-radius: 16px 16px 0px 0px;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .sub-container-t1 {
    padding: 16px 24px;
    display: flex;
    gap: 40px;
  }
  
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  
  .title-img-container {
    width: 20%;
    .header-t1 {
      color: #b10f15;
      font-size: 26px;
      line-height: 26px;
      font-weight: 500;
      margin: 0px 0px 20px;
    }
    .img-t1 {
      width: 180px;
      height: 180px;
      object-fit: contain;
    }
  }
  
  .prices-container {
    display: flex;
    justify-content: space-between;
    text-align: left;
    align-items: center;
    border-radius: 7px;
    border: solid 1px #a3a3a3;
    background-color: #fff;
    padding: 0px 15px;
    margin: 0 0 10px;
    height: 62px;
    max-height: 75px;
  }
  .custom-radio-btn {
    border: 2px solid white;
    box-shadow: 0 0 0 1px black;
    appearance: none;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    margin-right: 10px;
    background-color: #fff;
    transition: all ease-in 0.1s;
    &:checked {
      background-color: black;
    }
  }
  
  .yearly-price-t1 {
    color: #e63a47;
    text-align: end;
    font-size: 24px;
  }
  .old-price-t1 {
    font-size: 14px;
    color: #15213d;
    margin-right: 10px;
    opacity: 0.6;
    text-decoration: line-through;
  }
  
  .discount-t1 {
    font-size: 12px;
    color: #bd231b;
  }
  .coupon-apply-text {
    font-size: 14px;
    color: #15213d;
    margin: 0px 0px 8px 0px;
  }
  .coupon-code-without-offer-t1 {
    display: flex;
    justify-content: center;
    align-items: end;
    margin: 15px 0px 20px 0px;
  }
  .validatedCouponCode-t1 {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background-color: #f1faee;
    padding: 8px 12px;
    border-radius: 4px;
    width: -webkit-fill-available;
  }
  .Subscribe-now {
    width: -webkit-fill-available;
    height: 46px;
    border-radius: 68px;
    background-color: #c7141b;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
  }
  .benefit-heading-t1 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .pricing-container-t1 {
    margin-top: 25px;
    width: 40%;
  }
  .top-container-t1 {
    margin-top: 25px;
    width: 40%;
  }
  
  .common-margin-t1 {
    margin-top: 36px;
  }
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    .carousel-main-container {
      width: 90%;
    }
  }
  @media screen and (max-width: 865px) {
    .top-heading-center-container {
      display: flex;
      justify-content: center;
      align-self: center;
    }
    .top-heading-container {
      width: 90%;
      margin: 16px 0px 24px 0px;
    }
    .carousel-main-container {
      width: 90%;
    }
    .individual-offer {
      width: 100%;
    }
    .header {
      display: none;
    }
    .buy-now {
      width: 90%;
      height: 46px;
    }
    .subscribe-btn {
      @extend .buy-now;
      left: 32px;
    }
    .coupon-code-text {
      width: 100%;
    }
    .validatedCouponCode {
      width: 90%;
    }
    .validatedCouponCode-position {
      width: 88%;
    }
    .coupon-code-container {
      width: 100%;
    }
  
    .sub-container-t1 {
      display: block;
    }
    .title-img-container {
      width: 100%;
      text-align: center;
    }
    .pricing-container-t1 {
      margin-top: 25px;
      width: 100%;
    }
    .top-container-t1 {
      margin-top: 25px;
      width: 100%;
    }
    .benefit-heading-t1 {
      text-align: center;
    }
    .coupon-apply-text {
      text-align: center;
    }
    .common-margin-t1 {
      margin-top: 0px;
    }
  }
  
  @media screen and (max-width: 567px) {
    .subscribe-btn {
      left: 18px;
    }
  
    .loader-text {
      margin-top: 40px;
      font-size: 16px;
    }
  }
  