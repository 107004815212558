.main-container {
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

:global(.ant-select) {
  width: 100%;
}
:global(.ant-select-selection-search-input) {
  height: 35px !important;
  cursor: pointer !important;
}
:global(.ant-select-single) {
  height: fit-content;
}
:global(.ant-select-multiple .ant-select-selector){
  padding: 0px 5px;
}
.common-select-box {
  :global(.ant-select-focused):not(.ant-select-disabled),
  :global(.ant-select-single):not(.ant-select-customize-input),
  :global(.ant-select-selector),
  :global(.ant-picker-focused) {
    border-color: #d9d9d9 !important;
    box-shadow: none !important;
    border-radius: 2px !important;
  }
  :global(.ant-select-single):not(.ant-select-customize-input),
  :global(.ant-select-selector) {
    border: none !important;
    background-color: transparent !important;
    text-align: left !important;
    height: 35px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    border-radius: 4px !important;
  }
  :global(.ant-select-selection-placeholder) {
    color: #cfcfcf;
  }
}
.common-dropdown-select {
  :global(.ant-select-item-option-active):not(.ant-select-item-option-disabled) {
    background-color: #fff;
  }
  :global(.ant-select-item-option-selected):not(.ant-select-item-option-disabled) {
    font-weight: 600;
    background-color: transparent;
  }
  :global(.ant-select-item-option) {
    &:hover {
      color: #fff;
      background-color: #fff;
    }
  }
}
.colorWhite {
  :global(.ant-select-selector) {
    color: #fff !important;
  }
}
.colorBlack {
  :global(.ant-select-selector) {
    color: black;
  }
}
.dropdown-options {
  padding: 5px 9px;
  :global(.ant-select-item-option-selected):not(.ant-select-item-option-disabled),
  :global(.ant-select-item-option-state) {
    color: #3d8dfa !important;
  }
}
.dropdown-options:hover {
  background-color: #3d8dfa !important;
  color: #fff;
  border-radius: 4px !important;
  :global(.ant-select-item-option-selected):not(.ant-select-item-option-disabled),
  :global(.ant-select-item-option-state) {
    color: #fff !important;
  }
}

.borderDefaultContentFormSectionSelect {
  :global(.ant-select-selector) {
    border: 1px solid #dedede !important;
  }
}