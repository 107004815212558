.flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.main-container {
  height: 100vh;
  background-color: #efefef;
}

.header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 44px;
  background-color: #ffffff;
  width: 100%;
  cursor: pointer;
}

.image-logo {
  object-fit: contain;
  max-height: 100px;
  max-width: 90%;
  margin: 0 auto;
}

.sub-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #EFEFEF;
}

.innner-container {
  width: 490px;
  padding: 26px 24px 32px;
  background-color: #ffffff;
  border-radius: 4px;
}

.title {
  font-family: 'Roboto';
  font-size: 20px;
  font-weight: 500;
  color: #484848;
  margin-bottom: 12px;
}

.input-field {
  width: 100%;
  margin-bottom: 12px;
}

.input-field input {
  height: 40px !important;
  width: 100% !important;
  font-size: 15px !important;
  outline: none !important;
  border: 1px solid #ADADAD !important;
  border-radius: 5px !important;
  padding: 0px 8px !important;
  color: #000 !important;
}

.mobile-code {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #adadad;
  display: flex;
  align-items: center;
  border-radius: 5px;
  height: 40px;
  padding: 0px 0px 0px 8px;
}

.phone-field {
  display: flex;
  width: 100%;
  gap: 12px;
}

.submitBtn {
  width: 100%;
  height: 50px;
  color: white;
  border: 1px solid #000000;
  background-color: #000000;
  border-radius: 8px;
  font-size: 16px;
  margin-top: 36px;
  font-weight: 700;
  cursor: pointer;
}

.delivery-address {
  color: #252525;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 9px;
}

@media (max-width: 576px) {
  .innner-container {
    width: 100%;
  }
  .main-container {
    background-color: #ffffff;
  }
  .sub-container {
    background-color: #ffffff;
    padding: 20px 0px;
  }
  .innner-container {
    padding: 0px 16px;
  }
}
