/* In your stylesheet (e.g., yourStyles.module.css) */

.loader {
  display: inline-block;
  width: 60px;
  height: 10px;
  position: relative;
}

.loader div {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  animation: blink 1.4s infinite both;
}

.loader div:nth-child(1) {
  left: 0;
  animation-delay: -0.32s;
}

.loader div:nth-child(2) {
  left: 20px;
  animation-delay: -0.16s;
}

.loader div:nth-child(3) {
  left: 40px;
}

@keyframes blink {
  0%,
  80%,
  100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
