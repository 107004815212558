// signUp screen css part
$border-color: #6d6d6d;
.link {
  text-decoration: underline;
  color: black;
  font-weight: 600;
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.main-container {
  height: 100vh;
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
}
.header-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 44px;
  background-color: #ffffff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  .image-logo {
    height: 46px;
    object-fit: contain;
    max-width: 90%;
    margin: 0 auto;
    cursor: pointer;
  }
}

.container {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 18px;

  .child-container {
    font-family: Roboto;
    position: relative;
    width: 490px;
    min-width: 320px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid #d0d0d0;
    padding: 40px;
    border-radius: 4px;
    .cross_icon {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      cursor: pointer;
    }
    .backArrow_icon {
      cursor: pointer;
      position: absolute;
      top: 14px;
      float: left;
      left: 16px;
    }
    .cross_back_icon {
      @extend .cross_icon;
      justify-content: space-between;
      flex-direction: row;
      cursor: pointer;
      position: absolute;
      top: 9px;
      float: left;
      left: 16px;
    }
    .heading-container {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .heading-text {
      font-family: Roboto;
      font-size: 24px;
      font-weight: 600;
    }
    .sub-text {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      font-size: 16px;
      font-family: Roboto;
    }
  }
}
.input-container {
  display: flex;
  align-items: center;
  border: 1px solid $border-color;
  border-radius: 4px;
  height: 46px;
  padding: 6px 12px;
  font-family: Roboto;
  margin-top: 8px;
  .country-code {
    margin: 0px 15px 0px 10px;
  }
  .vertical-line {
    border-right: 1px solid #e6e6e6;
    height: 35px;
  }
  :global(.ant-input) {
    border: none;
    font-size: 14px;
    font-family: Roboto;
  }
  :global(.ant-input:focus) {
    box-shadow: none;
  }
}
.form-container {
  margin-top: 20px;
  margin-bottom: 24px;
}
.input-container-common {
  position: relative;
  margin-top: 8px;
  img:nth-child(1) {
    position: absolute;
    top: 14px;
    left: 20px;
    z-index: 1;
  }
  img:nth-child(3) {
    position: absolute;
    top: 16px;
    right: 20px;
    z-index: 1;
  }
  :global(.ant-input) {
    border: 1px solid $border-color;
    background-color: #ffffff;
    font-family: Roboto;
    border-radius: 4px;
    padding: 8px;
    padding-left: 53px;
    font-size: 14px;
    height: 48px;
  }
  :global(.ant-input:focus) {
    border-color: $border-color !important;
    border-right-width: none;
    outline: 0;
    box-shadow: none;
  }
  :global(.ant-input:hover) {
    border-color: $border-color !important;
    border-right-width: none !important;
  }
  :global(.ant-picker-focused) {
    box-shadow: none;
    border: 1px solid $border-color;
  }
  :global(.ant-picker) {
    width: -webkit-fill-available;
    border: 1px solid $border-color;
    background-color: #ffffff;
    font-family: Roboto;
    border-radius: 4px;
    padding: 8px;
    padding-left: 53px;
    font-size: 14px;
    height: 48px;
  }
}

.btn-style {
  all: unset;
  width: -webkit-fill-available;
  height: 48px;
  text-align: center;
  background-color: #000000;
  color: white;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  img {
    margin-right: 12px;
    width: 28px;
  }
}
.btn-margin {
  margin-top: 20px;
}
.continueWithContainer {
  display: flex;
  padding: 2px;
  width: -webkit-fill-available;
  justify-content: space-between;
  margin-top: 24px;
  margin-bottom: 16px;
}

.continueWith {
  text-align: center;
  font: normal normal 600 12px/33px Roboto;
  font-family: Roboto;
  letter-spacing: 0px;
  color: #000000;
  white-space: nowrap;
  padding: 0px 10px;
}

.horizontalLine {
  width: -webkit-fill-available;
  margin: auto 0px auto;
}
.google-btn {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fill-available;
  height: 48px;
  background-color: #ffffff;
  border: 1px solid $border-color;
  border-radius: 4px;
  font-size: 15px;
  font-weight: 500;
  width: 100%;
  cursor: pointer;
  img {
    margin-right: 12px;
    width: 28px;
  }
}
.facebook-btn {
  @extend .google-btn;
  background-color: #ffffff;
  color: #000000;
  border: 1px solid #6d6d6d;
  margin-top: 25px;
  width: 100%;
}

.dropdown-container {
  position: relative;
  margin-top: 8px;
  img {
    position: absolute;
    top: 14px;
    left: 20px;
  }
  :global(.ant-select-selector) {
    padding: 5px 22px 5px 52px !important;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
  }
}

// setpassword scss
.setpasswprd-form-container {
  margin-top: 20px;
  margin-bottom: 16px;
}
.validation-container {
  font-size: 13px;
  color: #a5a5a5;
  margin-top: 20px;
  margin-bottom: 18px;
  .validation_text {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 2px;
  }
}
.recaptcha-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e6e6e6;
  background-color: #fafafa;
  border-radius: 4px;
  padding: 10px;
  margin-top: 25px;
}
.recaptcha-checkbox {
  display: flex;
  align-items: center;
  height: 30px;
  gap: 10px;
  font-size: 14px;
  div {
    font-weight: 600;
    font-family: Roboto;
  }
  input {
    height: 25px;
    width: 25px;
  }
}
.some-checks {
  @extend .recaptcha-checkbox;
  div {
    font-weight: 500;
    font-family: Roboto;
    color: #575757;
  }
}
.by-continue {
  text-align: center;
  padding: 0px 10px;
  margin-top: 24px;
  font-family: Roboto;
  font-size: 12px;
  color: #0f0f0f;
  span {
    color: #0f0f0f;
    text-decoration: underline;
    cursor: pointer;
  }
  a {
    color: #0f0f0f;
    text-decoration: underline;
    cursor: pointer;
  }
  .noHover {
    pointer-events: none;
  }
}
.already-account {
  text-align: center;
  margin-top: 16px;
  font-size: 14px;
}

// verify OTP
.verify_otp {
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
}
.message {
  color: #7b7b7b;
  font: normal normal 400 14px/20px Roboto;
  text-align: center;
  margin: 10px 0px 30px 0px;
}
.inputField {
  border: none;
  border-bottom: 1px solid;
  outline: none;
  text-align: center;
  font: normal normal normal 24px/30px Roboto;
  letter-spacing: 0px;
  width: 15%;
  border-radius: 0px;
}

.inputField:focus {
  border-bottom: 1px solid #a5a5a5;
}

.otpForm {
  display: flex;
  justify-content: center;
  gap: 15px;
}
.resend-otp-container {
  margin-top: 30px;
  text-align: center;
}

.resend-otp {
  text-decoration: underline;
  color: #000;
  font: normal normal 600 14px/30px Roboto;
  letter-spacing: 0.28px;
  cursor: pointer;
}

.resend-otp-time {
  color: #7b7b7b;
  font: normal normal 400 14px/30px Roboto;
  letter-spacing: 0.28px;
}
.error-message {
  text-align: left;
  margin-top: 11px;
  font-size: 12px;
  font-weight: 600;
  color: #e91212;
  font-family: Roboto;
}

//dropdown search image
.dropDown-container {
  :global(.ant-select-selector) {
    border: 0px solid !important;
  }
}
.dropdown-flag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
  font-family: Roboto;
  .flag-code {
    font-size: 14px;
    font-weight: 500px;
  }
  .flag-name {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .flag {
    font-size: 25px;
  }
}
.dropDown-search {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  padding: 6px;
  img {
    margin-right: 6px;
  }
  :global(.ant-input) {
    background-color: #cbcbcb4d;
    font-family: Roboto;
    border-radius: 4px;
    padding: 8px;
    height: 35px;
    font-size: 14px;
    border: 0px solid;
  }
  :global(.ant-input:focus) {
    border-color: $border-color;
    border-right-width: none;
    outline: 0;
    box-shadow: none;
  }
  :global(.ant-input:hover) {
    border-color: $border-color !important;
    border-right-width: none !important;
  }
}

// loginEmail scss
.flex-box {
  display: flex;
  align-items: center;
}
.toggle-box {
  @extend .flex-box;
  justify-content: space-between;
  .common-flex-box {
    @extend .flex-box;
    gap: 10px;
    font: normal normal normal 14px/33px Roboto;
  }
  .forgot-password {
    font: normal normal normal 14px/33px Roboto;
    text-decoration: underline;
    cursor: pointer;
  }
}
.otp-btn {
  background-color: #ffffff;
  border: 1px solid #000000;
  color: #000000;
}

.m-top {
  margin-top: 16px;
}

.footer-container {
  text-underline-offset: 3px;
  padding: 16px 44px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  .footer-text {
    cursor: pointer;
    font-size: 8px;
    font-weight: 400;
    color: $border-color;
  }
  .footer-hanldes {
    display: flex;
    text-decoration: underline;
    gap: 16px;
  }
}
.contact-us-modal {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  margin: auto;
  padding: 100px 40px 0px;
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  width: 100%;
  opacity: 0.75;
}

.contact-us-container {
  width: 650px;
  height: auto;
  position: absolute;
  padding: 32px;
  border-radius: 12px;
  background-color: #fff;
}
.contact-us-heading {
  font-size: 28px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

.contact-us-body {
  display: flex;
}
.contact-us-body-left {
  width: 40%;
}
.contact-us-body-right {
  width: 60%;
}
.contact-us-left-item {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  width: max-content;
  color: #959595;
  line-height: 3;
}

.contact-us-right-item {
  font-size: 18px;
  text-align: left;
  color: #000;
  line-height: 3;
}
.contact-us-modal {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  bottom: 0px;
  margin: auto;
  padding: 100px 40px 0px;
}

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  width: 100%;
  opacity: 0.75;
}

.contact-us-container {
  width: 650px;
  height: auto;
  position: absolute;
  padding: 32px;
  border-radius: 12px;
  background-color: #fff;
}
.contact-us-heading {
  font-size: 28px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

.contact-us-body {
  display: flex;
}
.contact-us-body-left {
  width: 40%;
}
.contact-us-body-right {
  width: 60%;
}
.contact-us-left-item {
  font-size: 18px;
  font-weight: 500;
  text-align: left;
  width: max-content;
  color: #959595;
  line-height: 3;
}

.contact-us-right-item {
  font-size: 18px;
  text-align: left;
  color: #000;
  line-height: 3;
}


@media (max-width: 450px) {
  .contact-us-container{
    padding: 10px;
  }
  .cross-icon-container {
    left: 97% !important;
    top: -31px !important;
  }
  .container {
    margin-top: 4px;
    .child-container {
      padding: 20px 16px;
      width: 100%;
      min-width: 100%;
      border: 0px solid;
    }
  }
  .footer-container {
    display: block;
    text-align: center;
    padding: 16px 10px;
    .footer-hanldes {
      margin-top: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-decoration: underline;
      gap: 16px;
    }
  }
  .main-container {
    background-color: #ffffff;
  }
}

@media screen and (max-width: 520px) {
  .paddingMobile {
    padding-bottom: 8px;
  }

  .contact-us-right-item {
    font-size: 12px;
  }
  .contact-us-left-item {
    font-size: 12px;
  }

  .contact-us-container {
    width: -webkit-fill-available;
    margin: 10px;
  }
}

@media (max-width: 750px) {
  .contact-us-container {
    width: 85%;
  }
}


.cross-icon-container {
  cursor: pointer;
  position: relative;
  left: 102%;
  top: -48px;
}
.cross-icon {
  font-size: 15px;
  width: 34px;
  height: 34px;
  border: 3px solid #fff;
  border-radius: 30px;
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 16%);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
  font-weight: 600;
}