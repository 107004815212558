.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #000000;
  opacity: 0.2;
}



.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#loader {
  position: relative;
  display: flex;
  width: 25%;
  justify-content: space-between;
  margin-bottom: 20px;
  /* Add space between loader and text */
}

#loader div {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  background: #d91e36;
}

#loader div:nth-child(1) {
  animation: loader 3s linear infinite 0s;
}

#loader div:nth-child(2) {
  animation: loader 3s linear infinite 0.15s;
}

#loader div:nth-child(3) {
  animation: loader 3s linear infinite 0.3s;
}

#loader div:nth-child(4) {
  animation: loader 3s linear infinite 0.45s;
}

@keyframes loader {
  0% {
    opacity: 0;
    transform: scale(0.3);
    background: #59cd90;
  }

  25% {
    opacity: 1;
    transform: scale(1.8);
    background: #0072bb;
  }

  50% {
    opacity: 0;
    transform: scale(0.3);
    background: #fe4a49;
  }

  75% {
    opacity: 1;
    transform: scale(1.8);
    background: #fed766;
  }

  100% {
    opacity: 0;
  }
}

.loader-text {
  text-align: center;
  color: black;
  font-family: Arial, sans-serif;
  /* or any preferred font */
  font-weight: 600;
  font-size: 24px;
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 55%;
}