@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@300;400;500;700&display=swap');

.flex-center {
  display: flex;
  align-items: center;
}
.outercontainer {
  position: absolute;
  width: 100%;
  max-width: 358px;
  height: 100vh;
  padding: 20px 0;
  overflow: auto;
  font-family: Roboto;
}

.active_heading {
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: 600;
}

.head {
  width: 96%;
  display: flex;
  padding: 5px 0;
  padding-bottom: 15px;
  padding-right: 28px;
  margin: 0 auto;
}

.arrow {
  width: 10%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topic {
  width: 90%;
  text-align: center;
  font-size: 17px;
  font-weight: 700;
}

.active {
  width: 95%;
  margin: 20px auto 16px auto;
}

.inactive {
  width: 95%;
  margin: 0 auto;
}

.list {
  display: flex;
  flex-direction: column;
  margin: 10px auto;
  width: 100%;
  box-shadow: 0 0 2px;
  border-radius: 5px;
  font-size: 14px;
  padding: 10px;
  gap: 7px;
}

.list-item {
  display: flex;
  justify-content: space-between;
}

.cancelsubs {
  padding-top: 5px;
  font-size: 12px;
  text-decoration: 1px underline;
  text-underline-offset: 3px;
  cursor: pointer;
}

.buynow {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 16px;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.loader {
  border: 16px solid #d3d9dd;
  border-top: 16px solid #b4b4b4;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1.5s linear infinite;
  margin-bottom: 20px;
}

.buttoncontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 15px;
  width: 100%;
}

.content {
  padding-top: 20px;
  width: 93%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: calc(100vh - 150px);
  overflow: auto;
  user-select: none;
}

.plans {
  border-radius: 8px;
  padding: 5px 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.plan_title {
  font-size: 16px;
  font-weight: 700;
  text-align: left;
}

.plan_mid_part {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.plan_duration {
  font-size: 14px;
}

.plan_price {
  font-size: 28px;
  font-weight: 700;
  text-align: left;
}

.plan_benefits {
  width: max-content;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  text-decoration: underline;
  cursor: pointer;
}

.tabs-container {
  cursor: pointer;
  color: #000000;
  display: flex;
}

.active-tabs {
  font-size: 14px;
  padding: 0 7px;
  font-weight: 600;
}

.tabs {
  font-size: 14px;
  padding: 0 7px;
}

.border-bottom {
  border: 1px solid black;
  border-radius: 10px;
}

.benefits {
  color: #000000;
  font-size: 12px;
  padding: 10px 5px 0 5px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* media query for mobile */
@media (max-width: 767px) {
  .outercontainer {
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
  }
}



:global(.ant-modal-mask) {
  background-color: #000000 !important;
  opacity: 0.2 !important;
}
:global(.ant-modal) {
  top: 60px;
}
.container {
  :global(.ant-modal-close) {
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    padding: 0;
    color: rgba(0, 0, 0, 0.45);
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    background: transparent;
    border: 0;
    outline: 0;
    cursor: pointer;
    transition: color 0.3s;
  }

  :global(.ant-modal-close:hover) {
    background-color: transparent;
  }

  :global(.ant-modal-header) {
    background-color: #f9f9fb;
    font-size: 14px;
    font-weight: 600;
    color: #292929;
    border-radius: 8px !important;
    border-bottom: none;
    padding: 16px 24px;
  }

  :global(.ant-modal-close-x) {
    display: flex;
    position: relative;
    left: 0px;
    bottom: 40px;
    width: 25px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: white;
    height: 25px;
    color: black;
    padding: 7px;
  }

  :global(.ant-btn-primary) {
    display: none;
    margin-top: 20px;
    background: #0353e9;
    border-radius: 6px;
    height: 32px !important;
    border-color: none;
  }

  :global(.ant-btn) {
      display: none;
    text-align: center;
    border-color: none !important;
    &:hover {
      border-color: #0353e9 !important;
    }
    &:focus {
      border-color: #0353e9 !important;
    }
  }
  :global(.ant-modal-content) {
    padding: 0px;
  }
}