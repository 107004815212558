@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@300;400;500;700&display=swap');


.outercontainer{
    position: absolute;
    width: 100%;
    max-width: 358px;
    height: 100vh;
    padding: 20px 0;
    /* overflow: auto; */
    font-family: Roboto;
    overflow: auto;
    
}
.editable{
    height: 90%;
    /* border: 1px solid white; */
    display: flex;
    justify-content: center;
}
.head{
    
    width: 90%;
    height: fit-content;
    /* border: 1px solid red; */
    display: flex;
    padding: 5px 0;
    padding-right: 28px;
    padding-bottom: 15px;
   
    margin: 0 auto;
}
.arrow{
    width: 10%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
.topic{
    width: 90%;
    /* color: white; */
    text-align: center;
    font-size: 17px;
    font-weight: 700;
}

.content{
    position: relative;
    margin: 15px auto;
    width: 100%;
    /* border: 1px solid white; */
    max-height: auto;
    overflow: auto;
    gap: 24px;
}
.details{
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    border-bottom: 1px solid rgba(228,228,228,.30);
}
/* rgba(80, 71, 71, 0.667) */
.dwn{
    /* color: #FF4080; */
    font-size: 12px;
    cursor: pointer;
}
.date{
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}
.payment{
    font-size: small;
    display: flex;
    justify-content: space-between;
}
.price{
    margin: 7px 0;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}
.paymenttype{
    opacity: .5;
    font-size: 12px;
}
.actualvalue{
    font-size: 14px;
}
.name{
    width: 207px;
    word-wrap: normal;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.cost{
    width: auto;
    font-size: 14px;
    font-weight: 600;
}
/* media query for mobile */
@media (max-width: 767px) {
    .outercontainer{
        max-width: 100%;
        max-height: 100%;
        overflow: auto;
    }
}

.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; 
    text-align: center;
  }
  
  .loader {
    border: 16px solid #d3d9dd; 
    border-top: 16px solid #b4b4b4;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 1.5s linear infinite;
    margin-bottom: 20px; 
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }