.main-container {
  font-family: Noto Sans;
}
.flex-center {
  display: flex;
  align-items: center;
}
.flex-Justify-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-Justify-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-JustifyCenter {
  display: flex;
  justify-content: center;
}
.header-logo {
  @extend .flex-Justify-center;
  background-color: #f4f4f4;
  padding: 12px;
  width: 100%;
  cursor: pointer;
}

.image-logo {
  object-fit: contain;
  max-height: 100px;
  max-width: 90%;
  margin: 0 auto;
}
.header-title {
  font-size: 24px;
  font-weight: 600;
}
.logout{ 
    position: absolute;
    margin-top: 6px;
    right: 0;
    width: 130px;
    padding: 8px 12px;
    text-align: center;
    background-color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    color: #D62525;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}
.container {
  height: 88px;
  padding: 16px 40px;
  cursor: pointer;
  .profile-container {
    @extend .flex-center;
    padding: 8px 16px;
    gap: 16px;
    border-radius: 12px;
    background-color: #f5f5f5;
    .profile-name {
      border-radius: 50%;
      padding: 5px 6px;
      font-size: 20px;
      font-weight: 600;
      background-color: #000000;
      color: #ffffff;
    }
  }
}

.body-container {
  padding: 0px 80px;
  .containe-2 {
    margin-top: 25px;
    .welcome-back {
      font-weight: 400;
      font-size: 20px;
      color: #7d7d7d;
      span {
        color: #000000;
        font-weight: 600;
      }
    }
  }
  .licenses-box {
    width: 32%;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    .licenses-title {
      font-size: 14px;
      font-weight: 400;
    }
    .number-licenses {
      font-size: 32px;
      font-weight: 600;
    }
  }
  .search-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    width: 341px;
    background: #ffffff;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    padding-right: 8px;
    :global(.ant-input) {
      border: none;
    }
    :global(.ant-input:focus),
    :global(.ant-input-focused) {
      border-color: none;
      border-right-width: 0px !important;
      outline: 0;
      box-shadow: none;
    }
    &:hover {
      border: 1px solid #d0d0d0;
    }
  }
  .download-icon {
    @extend .flex-Justify-center;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    border: 1px solid #d2d2d2;
    background-color: #f2f2f2;
    height: 40px;
    width: 115px;
    gap: 10px;
  }
  .range-container {
    :global(.ant-picker) {
      border: none;
    }
    :global(.ant-picker-focused) {
      box-shadow: none;
    }
    :global(.ant-picker-clear) {
      display: none;
    }
    .date-picker-wrap {
      width: 330px;
      border: 1px solid #d9d9d9;
      border-radius: 4px;
      padding: 0px 5px;
      background-color: #fff;
      font-size: 12px;
      cursor: pointer;
      height: 40px;
      display: flex;
      justify-content: center;
      :global(.hide-suffix-icon) {
        display: none;
      }
      .divider {
        width: 30px;
        height: auto;
        justify-content: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .arrow-img-wrapper {
          width: 16px;
          height: 100%;
          display: flex;
          object-fit: cover;
          align-items: center;
        }
      }
    }
  }
}

.table-container {
  margin-top: 10px;
  padding: 16px 0px 0px 0px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  .container-space-between {
    @extend .flex-center;
    justify-content: space-between;
    padding: 0px 16px;
  }
  .active-user-title {
    font-weight: 500;
    font-size: 16px;
  }
  .upload_Icon {
    @extend .flex-center;
    font-size: 14px;
    font-weight: 500;
    gap: 15px;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: 4px;
    border: 1px solid #d2d2d2;
  }
  .add-new-user {
    @extend .upload_Icon;
    color: #ffffff;
    background-color: #000000;
    border: 1px solid #000000;
    padding: 10px 12px;
  }
  .table-Container {
    margin-top: 30px;
    :global(.ant-table-thead) {
      tr {
        th {
          font-weight: 500 !important;
          background: none !important;
        }
      }
    }
    :global(.ant-pagination-item) {
      font-weight: 600;
      border-color: #ffffff !important;
      &:hover {
        a {
          color: black;
        }
      }
    }
    :global(.ant-pagination-item-active a) {
      color: black;
      background-color: #d2d2d2;
      font-weight: 600;
      border-radius: 6px;
    }
    :global(.ant-pagination) {
      margin-right: 20px !important;
    }
    :global(.ant-pagination-prev),
    :global(.ant-pagination-next),
    :global(.ant-pagination-item-link) {
      font-size: 15px !important;
      color: white !important;
      border: none !important;
      background-color: black;
      height: 32px;
    }
  }
  .table-title {
    width: -webkit-fill-available;
    font-size: 14px;
    font-weight: 500;
    color: #545454;
  }
  .table-value {
    font-size: 14px;
    font-weight: 500;
    color: #545454;
  }
  .status {
    font-size: 16px;
    font-weight: 400;
    width: fit-content;
    background-color: #e6f5ee;
    padding: 4px 12px;
    border-radius: 8px;
    border: 0.5px solid #069855;
    color: #069855;
    text-align: center;
  }
}
.wrap-img-menu {
  position: relative;
  width: 40px;
}
.edit-delete-update-wrap {
  position: absolute;
  z-index: 2;
  right: 8px;
  width: 124px;
  background: #ffffff;
  box-shadow: 0px 4px 10px #12121233;
  border-radius: 8px;
  :global(.ant-divider-horizontal) {
    margin: 8px 0;
  }
  .arrow {
    position: relative;
    left: 78px;
    top: -6px;
    svg {
      path {
        fill: #fff;
      }
    }
  }
  .text {
    padding-left: 18px;
    text-align: left;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    color: #292929;
  }
  .red-text {
    @extend .text;
    color: #f54a29;
  }
}
.edit-delete-update-img {
  cursor: pointer;
  margin-left: 40px;
  img {
    width: 4px;
  }
}

.imageUploader {
  position: relative;
  input {
    opacity: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.modal_Container {
  padding: 16px 12px;
  .modal_title {
    font-size: 16px;
    font-weight: 500;
  }
  .modal_description {
    font-size: 12px;
    font-weight: 400;
    color: #b2b2b2;
    margin-top: 4px;
  }
  .from-field {
    @extend .flex-Justify-between;
    align-items: start;
    border-bottom: 1px solid #d2d2d2;
    padding: 12px;
    .name {
      font-size: 14px;
      font-weight: 500;
      width: 49%;
    }
    .input-style {
      width: 49%;
      :global(.ant-input) {
        background-color: #ffffff;
        border-radius: 4px;
        padding: 8px;
        height: 40px;
        width: 100%;
      }
      :global(.ant-input:focus) {
        border-color: #d2d2d2;
        border-right-width: none;
        outline: 0;
        box-shadow: none;
      }
      :global(.ant-input:hover) {
        border-color: #d2d2d2 !important;
        border-right-width: none !important;
      }
    }
  }
}
.btns-container {
  @extend .flex-center;
  justify-content: flex-end;
  margin-top: 14px;
  .save-changes {
    padding: 12px 20px;
    font-family: Noto Sans;
    color: #ffffff;
    font-weight: 500;
    background-color: #0353e9;
    border-radius: 4px;
    cursor: pointer;
  }
}
.delete_modal_Container {
  text-align: center;
  padding: 50px;
  .delete_modal_title {
    font-size: 20px;
    font-weight: 600;
    color: #d62525;
    margin-top: 15px;
  }
  .delete_modal_description {
    font-size: 16px;
    font-weight: 400;
    color: #898989;
    margin-top: 20px;
  }
}
.goback-btn {
  width: -webkit-fill-available;
  font-size: 14px;
  font-weight: 500;
  padding: 16px 20px;
  border-radius: 4px;
  background-color: #ffffff;
  border: 1px solid rgba(138, 141, 149, 0.2);
  cursor: pointer;
}
.delete-btn{
  @extend .flex-Justify-center;
  @extend .goback-btn;
  gap: 10px;
  background-color: #D62525;
  color: #ffffff;
  border: 1px solid #D62525;
  margin: 40px 0px 20px 0px;
}
