@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arima:wght@100;300;400;500;700&family=Eczar:wght@400;500;700&family=Hind+Madurai:wght@300;400;500;700&family=Hind+Siliguri:wght@300;400;500;700&family=Laila:wght@300;400;500;700&family=Noto+Sans+Malayalam:wght@100;300;400;500;700&family=Noto+Sans+Tamil:wght@100;300;400;500;700&family=Noto+Sans:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400&family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,400&family=Rozha+One&family=Tiro+Bangla:ital@0;1&family=Tiro+Telugu:ital@0;1&display=swap');

.outercontainer {
  font-family: Roboto;
  position: absolute;
  width: 100%;
  max-width: 358px;
  height: 100vh;
  padding: 20px 0;
  overflow: auto;
}
.editable {
  /* border: 1px solid white; */
  display: flex;
  justify-content: center;
}
.head {
  width: 90%;
  height: fit-content;
  /* border: 1px solid red; */
  display: flex;
  padding: 5px 0;
  padding-bottom: 15px;
  /* border-bottom: 1px solid rgba(228, 228, 228, .30); */
}
.arrow {
  width: 10%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topic {
  width: 90%;
  /* color: white; */
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  padding-right: 28px;
}
.deleteimage {
  margin-top: 30px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  width: 100%;
}
.confirmtext {
  padding: 4px 20px;
  /* color: white; */
  font-size: 14px;
  display: block;
}
.change {
  width: 100%;
  height: 100%;
  /* border-radius: 8px; */
  background-color: transparent;
  /* border:1px solid #402d6c; */
  color: white;
  padding: 10px;
  font-size: 14px;
  gap: 4px;
  outline: none;
}
.inputdiv {
  width: 90%;
  margin: 5px auto;
  height: 40px;
  border-radius: 5px;
}
.change {
  border: none;
}
.btndiv {
  display: flex;
  justify-content: center;
  margin: 24px 0;
}

.blur {
  opacity: 0.3;
}

/* media query for mobile */
@media (max-width: 767px) {
  .outercontainer {
    max-width: 100%;
    max-height: 100%;
    overflow: auto;
  }
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
}

.loader {
  border: 16px solid #d3d9dd;
  border-top: 16px solid #b4b4b4;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 1.5s linear infinite;
  margin-bottom: 20px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.confirmationBox {
  text-align: center;
  padding: 0 20px 20px 20px;
  max-width: 90%;
  margin: 0 auto;
}
.confirmationBox h3 {
  font-size: 20px;
  font-weight: bold;
  color: #d9534f;
  margin-bottom: 10px;
}
.confirmationBox p {
  font-size: 14px;
  color: #333;
  line-height: 1.5;
}
